export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'presupuestocli.update',
      {
        values: {
          idpresupuestocli: id,
          descripcion_sistema: formData.descripcion_sistema,
          titulo: formData.titulo,
          idcliente_direccion: formData.cliente_direccion?.idcliente_direccion,
          idagente_comercial: formData.idagente_comercial,
          idcanal_comunicacion: formData.idcanal_comunicacion,
          idcanal_conocimiento: formData.idcanal_conocimiento,
          idtipo_riesgo: formData.tipo_riesgo?.idtipo_riesgo,
          idgrado_seguridad: formData.idgrado_seguridad,
          idfpago_sistema: formData.idfpago_sistema,
          idcondicion_pago: formData.idcondicion_pago,
          idfpago_cuotas: formData.idfpago_cuotas,
          idcondicion_pago_cuota: formData.idcondicion_pago_cuota,
          observaciones: formData.observaciones,
          notas_internas: formData.notas_internas,
        },
      },
    )
  },
}
